import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader.js'
import gsap from 'gsap'
import lilGuiUmd, * as dat from 'lil-gui'


/**
 * Loader
 */
// const textureLoader = new THREE.TextureLoader()
// const groundColorTexture = textureLoader.load("/textures/ground/color.jpg")
// // const doorAlphaTexture = textureLoader.load("/textures/door/alpha.jpg")
// const groundAmbientOcclusionTexture = textureLoader.load("/textures/ground/ambientOcclusion.jpg")
// const groundHeightTexture = textureLoader.load("/textures/ground/height.png")
// const groundNormalTexture = textureLoader.load("/textures/ground/normal.jpg")
// // const doorMetalnessTexture = textureLoader.load("/textures/door/metalness.jpg")
// const groundRoughnessTexture = textureLoader.load("/textures/ground/roughness.jpg")

// groundColorTexture.repeat.set(4,4,4)
// groundAmbientOcclusionTexture.repeat.set(4,4,4)
// groundNormalTexture.repeat.set(4,4,4)
// groundRoughnessTexture.repeat.set(4,4,4)

// groundColorTexture.wrapS = THREE.RepeatWrapping
// groundAmbientOcclusionTexture.wrapS = THREE.RepeatWrapping
// groundNormalTexture.wrapS = THREE.RepeatWrapping
// groundRoughnessTexture.wrapS = THREE.RepeatWrapping

// groundColorTexture.wrapT = THREE.RepeatWrapping
// groundAmbientOcclusionTexture.wrapT = THREE.RepeatWrapping
// groundNormalTexture.wrapT = THREE.RepeatWrapping
// groundRoughnessTexture.wrapT = THREE.RepeatWrapping
/**
 * Base
 */
// Debug
// const gui = new dat.GUI()
// gui.close()
// gui.closed = true
// gui.closeOnTop = true

// console.log(gui)
//gui.visible = false
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()



/**
 *  evnironment
 */
const cubeTextureLoader = new THREE.CubeTextureLoader()
const environmentMap = cubeTextureLoader.load([
    '/textures/environmentMaps/0/px.png',
    '/textures/environmentMaps/0/nx.png',
    '/textures/environmentMaps/0/py.png',
    '/textures/environmentMaps/0/ny.png',
    '/textures/environmentMaps/0/pz.png',
    '/textures/environmentMaps/0/nz.png'
])
environmentMap.encoding = THREE.sRGBEncoding

scene.background = environmentMap
scene.environment = environmentMap
/**
 * Axes helper
 */
const axesHelper = new THREE.AxesHelper()
// scene.add(axesHelper)

/**
 * Models
 */
const loadingManager = new THREE.LoadingManager(
    //loaded
    () => {
        console.log(document.querySelector('.loader-wrapper'))
        const element = document.querySelector('.loader-wrapper')
        console.log(element)
        element.classList.add('fadeOut')
        const element2 = document.querySelector('.loader')
        element2.classList.add('hide')
        const element3 = document.querySelector('.loader-inner')
        element3.classList.add('hide')
    },
    //progress
    () => {
        console.log('progress')
    }
)
const dracoLoader = new DRACOLoader(loadingManager)
dracoLoader.setDecoderPath('/draco/')


const gltfloader = new GLTFLoader(loadingManager)
gltfloader.setDRACOLoader(dracoLoader)

let mixer = null
let fox = null
gltfloader.load(
    '/models/Fox/glTF/Fox.gltf',
    (gltf)=>{
        mixer = new THREE.AnimationMixer(gltf.scene)
        const action = mixer.clipAction(gltf.animations[1])

        action.play()

        fox = gltf.scene.children[0]
        fox.scale.set(0.15,0.15,0.15)
        fox.position.y = 36


        scene.add(fox)

    }
)

let sphere = null
gltfloader.load(
    '/models/moon/scene.gltf',
    (gltf)=>{

        sphere = gltf.scene.children[0].children[0].children[0].children[0].children[0]
        sphere.geometry.center()


        scene.add(sphere)

    }
)



/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.2)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.02)
directionalLight.position.set(0,100,0)

const targetObject = new THREE.Object3D();
targetObject.position.set(0,50,0)
scene.add(targetObject);
directionalLight.target = targetObject;
scene.add(directionalLight)
const directionalLightHelper = new THREE.DirectionalLightHelper(directionalLight)
scene.add(directionalLight)



//scene.add(directionalLight2)
const hemisphereLight = new THREE.HemisphereLight(0xffffff,0xC2B280,0.2)//up, bottom, intensity
scene.add(hemisphereLight)


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})
/**
 * raycaster
 */
const raycaster = new THREE.Raycaster()

/**
 * Curosr
 */
const cursor = new THREE.Vector2() //mouse can't go in z direction
let clicked = false
window.addEventListener('click', (event) =>{
    cursor.x = event.clientX / sizes.width * 2 - 1
    cursor.y = -(event.clientY / sizes.height) * 2 +1
    clicked = true
    //console.log(cursor)
})
/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height)
camera.position.set(0,55,115)

scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
if(controls.enabled)
controls.target.set(0, 36, 0)
controls.enableDamping = true
controls.dampingFactor = 0.01
controls.maxDistance = 200
controls.minDistance = 50
controls.maxPolarAngle = Math.PI/1.5;
controls.zoomSpeed = 0.2

/**
 * Points
 */
const points = [
    {
        position: new THREE.Vector3(0, 70, 100),
        element: document.querySelector('.point-0')
    }
]

const burgerMenu = document.querySelector('#nav-icon2');
burgerMenu.addEventListener("click", () => {
    if(controls.enabled == false) {
        burgerMenu.classList.remove('open')
        orbitView()
    } else {
        burgerMenu.classList.add('open')
        firstPerson()
    }

    //console.log(burgerMenu.classList)
});
/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime


    //move the fox
    if(mixer!=null) mixer.update(deltaTime)

    //move the globe
    if(sphere!=null) sphere.rotation.x = -elapsedTime/2


    if(fox!=null && cursor!=null && clicked) {
        raycaster.setFromCamera(cursor,camera)

        const intersect = raycaster.intersectObject(fox)

        if(intersect.length > 0){
            //transition camera
            if(controls.enabled == true) {
                firstPerson()
                clicked = false
            }
            else {
                orbitView()
                clicked = false
            }

        }
    }




    // Update controls
    controls.update()
    //console.log(camera.position)
    if(controls.enabled == false) {
        camera.position.y= camera.position.y + Math.sin(elapsedTime*10)*0.1
    }



    /**
     * rendering the html
     */
    for(const point of points) {
        //save clone of where the point is
        const screenPosition = point.position.clone()

        //get the normalized camera 2D position of the point x: +1->-1, y: +1->-1
        screenPosition.project(camera)
        if(controls.enabled == false) {
            point.element.classList.add('visible')
        } else {
            point.element.classList.remove('visible')
        }
        //get the pixel position to render the point
        const translateX = screenPosition.x * sizes.width * 0.5
        const translateY = -screenPosition.y * sizes.height * 0.5

                //update the html position of the point
        point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`

                // remove the element when it shouldn't be visible

    }

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

const firstPerson = () => {
    // controls.enabled = false
    gsap.to(camera.position, {
        x: 0,
        y: 50,
        z: -5,
        duration: 2,
        onUpdate: function() {
            document.querySelector('.ride--instruction').classList.remove('visible');
            controls.update();
        },
        onComplete: () => {

        }
    })
    gsap.to(controls.target, {
        x: 0,
        y: 50,
        z: 95,
        duration: 2,
        onUpdate: () => { controls.update();},
        onComplete: () => {
            document.querySelector('.unride--instruction').classList.add('visible');
            controls.enabled = false;
        }
    })
    if(!burgerMenu.classList.contains('open')) burgerMenu.classList.add('open')
    console.log("firstperson")


}


const orbitView = () => {
    gsap.to(camera.position, {
        x: 0,
        y: 55,
        z: 115,
        duration: 2,
        onUpdate: function () {
            controls.update();
            document.querySelector('.unride--instruction').classList.remove('visible');
        }

    })
    gsap.to(controls.target, {
        x: 0,
        y: 36,
        z: 0,
        duration: 4,
        onUpdate: () => { controls.update();},
        onComplete: () => {controls.enabled = true;
            document.querySelector('.ride--instruction').classList.add('visible')
        }
    })
    if(burgerMenu.classList.contains('open')) burgerMenu.classList.remove('open')
    controls.enabled = true
}
